import React, { useEffect, useState } from "react";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { useLocation } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { FiLoader } from "react-icons/fi";
import axios from "axios";
import { db, sendEmail } from "../utils/firebase";

// API base URL – adjust as needed
const API_URL = "https://circle-lgd7.vercel.app/api";

/**
 * Helper: Maps an array of inputs to an object keyed by inputName.
 * This lets you easily access e.g. room_name.
 */
function mapUserInput(inputs) {
  return inputs.reduce((acc, input) => {
    if (input.inputName) {
      acc[input.inputName] = input.value;
    }
    return acc;
  }, {});
}

/**
 * Helper: Calculate summary values from estimateData.
 */
function calculateValues(estimateData, paymentReceived) {
  if (!estimateData || !estimateData.products) {
    return {
      subtotal: 0,
      discountAmount: 0,
      amountAfterDiscount: 0,
      taxAmount: 0,
      totalPrice: 0,
      balanceAmount: 0,
    };
  }
  const subtotal = estimateData.products.reduce(
    (acc, product) => acc + parseFloat(product.item_total),
    0
  );

  let discountAmount = 0;
  if (estimateData.discount_type === "percentage") {
    discountAmount = subtotal * (estimateData.discount / 100);
  } else if (estimateData.discount_type === "fixed") {
    discountAmount = estimateData.discount;
  }

  const amountAfterDiscount = subtotal - discountAmount;

  let taxAmount = 0;
  if (estimateData.tax_type === "percentage") {
    taxAmount = amountAfterDiscount * (estimateData.tax / 100);
  } else if (estimateData.tax_type === "fixed") {
    taxAmount = estimateData.tax;
  }

  const totalPrice = amountAfterDiscount + taxAmount;
  const balanceAmount = totalPrice - (paymentReceived || 0);

  return {
    subtotal,
    discountAmount,
    amountAfterDiscount,
    taxAmount,
    totalPrice,
    balanceAmount,
  };
}

/**
 * Fetch the estimate from Firestore and then augment with cart data via API.
 */
async function getEstimate(estimateId, storeId) {
  try {
    // Reference the estimate document in Firestore
    const estimateRef = doc(db, "STORES", storeId, "Estimates", estimateId);
    const snapshot = await getDoc(estimateRef);
    if (!snapshot.exists()) {
      return {};
    }
    let estimate = snapshot.data();

    // Get cart data from your API
    const res = await axios.get(
      `${API_URL}/customers/${estimate.customerID}/order-carts/${estimate.orderID}`
    );
    const orderCart = res.data.orderCart;
    estimate.estimateData = {
      ...estimate.estimateData,
      products: orderCart.cart_items,
      total_price: parseFloat(orderCart.customer_cart_total),
      customer: {
        id: orderCart.customer.id,
        customer_name: orderCart.customer.name,
        customer_email: orderCart.customer.email,
        customer_phone: orderCart.customer.phone,
      },
      discount: res.data.discount.discount_rate,
      discount_type: res.data.discount.discount_type,
      tax: res.data.tax,
    };
    estimate.isConvertedToOrder = res.data.isConvertedToOrder;
    return estimate;
  } catch (error) {
    console.error("Error fetching estimate:", error);
    throw error;
  }
}

const EstimateDetails = () => {
  const location = useLocation();
  const [estimate, setEstimate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // (Additional state for email confirmation, PDF download, etc. can be added here)

  // Extract storeId and estimateId from the URL query parameters.
  const searchParams = new URLSearchParams(location.search);
  const storeId = searchParams.get("storeId");
  const estimateId = searchParams.get("estimateId");

  useEffect(() => {
    const fetchEstimate = async () => {
      if (!storeId || !estimateId) {
        setError(true);
        setLoading(false);
        return;
      }
      try {
        const est = await getEstimate(estimateId, storeId);
        setEstimate(est);
      } catch (err) {
        console.error("Error fetching estimate:", err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchEstimate();
  }, [storeId, estimateId]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <FiLoader className="animate-spin text-4xl text-blue-500" />
        <p className="mt-4 text-xl text-gray-600">Loading estimate...</p>
      </div>
    );
  }

  if (error || !estimate || !estimate.estimateData) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4">
        <h1 className="text-3xl font-bold text-gray-800">Estimate Not Found</h1>
        <p className="mt-2 text-lg text-gray-600 text-center">
          We couldn't find the estimate you're looking for. It might have been moved or deleted.
        </p>
        <a
          href="/"
          className="mt-6 bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 transition"
        >
          Go Back Home
        </a>
      </div>
    );
  }

  // Calculate summary values.
  const {
    subtotal,
    discountAmount,
    amountAfterDiscount,
    taxAmount,
    totalPrice,
    balanceAmount,
  } = calculateValues(estimate.estimateData, estimate.payment_received);

  // Map the inputs array into an object for easier access.
  // (Assuming each product in the cart has an inputs array; adjust as needed.)
  const userInput = mapUserInput(estimate.estimateData.products[0]?.inputs || []);

  return (
    <div className="p-8 bg-white shadow-lg rounded-lg max-w-4xl mx-auto my-10">
      {/* Header */}
      <div className="mb-8 text-center">
        <h1 className="text-4xl font-extrabold text-gray-800">
          Estimate Details
        </h1>
        <p className="text-gray-500 mt-2">Estimate No: {estimate.estimateNo}</p>
        <p className="text-gray-500">
          Valid Until:{" "}
          {new Date(estimate.validUntil.seconds * 1000).toLocaleDateString()}
        </p>
      </div>

      {/* Customer Information */}
      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800">
          Customer Information
        </h2>
        <div className="text-gray-700 mt-4 space-y-2">
          <p>
            <span className="font-medium">Name:</span>{" "}
            {estimate.estimateData.customer.customer_name}
          </p>
          {estimate.estimateData.customer.customer_email && (
            <p>
              <span className="font-medium">Email:</span>{" "}
              {estimate.estimateData.customer.customer_email}
            </p>
          )}
          {estimate.estimateData.customer.customer_phone && (
            <p>
              <span className="font-medium">Phone:</span>{" "}
              {estimate.estimateData.customer.customer_phone}
            </p>
          )}
        </div>
      </div>

      {/* Items Table */}
      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800">Items</h2>
        <div className="overflow-x-auto mt-6">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-300 px-4 py-3 text-left text-gray-600">
                  Item
                </th>
                <th className="border border-gray-300 px-4 py-3 text-left text-gray-600">
                  Description
                </th>
                <th className="border border-gray-300 px-4 py-3 text-center text-gray-600">
                  Qty
                </th>
                <th className="border border-gray-300 px-4 py-3 text-right text-gray-600">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {estimate.estimateData.products.map((product, index) => (
                <React.Fragment key={index}>
                  <tr className="hover:bg-gray-50">
                    <td className="border border-gray-300 px-4 py-3">
                      {product.product.name}
                    </td>
                    <td className="border border-gray-300 px-4 py-3 whitespace-pre-line text-sm w-fixed">
                      <div className="min-w-32">
                      {
                        // render input name: value
                        product.inputs.map((input)=>{

                          return (
                            <p>
                             {input.value.length > 0 ? input.measurementUnitId ? input.inputName + " : " + input.value + " " + input.measurementUnit.toLowerCase() + " " : input.inputName + " : " + input.value + " "  : " "} 
                            </p>
                          )
                        })
                      }
                      </div>
        
                    </td>
                    <td className="border border-gray-300 px-4 py-3 text-center">
                      {product.quantity}
                    </td>
                    <td className="border border-gray-300 px-4 py-3 text-right">
                      ${parseFloat(product.item_total).toFixed(2)}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Summary */}
      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800">Summary</h2>
        <div className="mt-6 text-gray-700">
          <div className="flex justify-between py-2">
            <span>Subtotal:</span>
            <span>${subtotal.toFixed(2)}</span>
          </div>
          <div className="flex justify-between py-2">
            <span>
              Discount (
              {estimate.estimateData.discount}
              {estimate.estimateData.discount_type === "percentage" ? "%" : ""}
              ):
            </span>
            <span>-${discountAmount.toFixed(2)}</span>
          </div>
          <div className="flex justify-between py-2">
            <span>Amount after Discount:</span>
            <span>${amountAfterDiscount.toFixed(2)}</span>
          </div>
          <div className="flex justify-between py-2">
            <span>
              Tax (
              {estimate.estimateData.tax}
              {estimate.estimateData.tax_type === "percentage" ? "%" : ""}):
            </span>
            <span>+${taxAmount.toFixed(2)}</span>
          </div>
          <div className="flex justify-between py-4 border-t border-gray-300 mt-4">
            <span className="font-bold text-xl">Total Price:</span>
            <span className="font-bold text-xl">${totalPrice.toFixed(2)}</span>
          </div>
          <div className="flex justify-between py-2">
            <span>Payment Received:</span>
            <span>-${estimate.payment_received.toFixed(2)}</span>
          </div>
          <div className="flex justify-between py-4 border-t border-gray-300 mt-4">
            <span className="font-bold text-xl">Balance Amount:</span>
            <span className="font-bold text-xl">${balanceAmount.toFixed(2)}</span>
          </div>
        </div>
      </div>

      {/* Notes */}
      {(estimate.notes.customer_note ||
        estimate.notes.terms_and_conditions) && (
        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-800">Notes</h2>
          {estimate.notes.customer_note && (
            <p className="text-gray-700 mt-4">
              <strong>Customer Note:</strong>{" "}
              {estimate.notes.customer_note}
            </p>
          )}
          {estimate.notes.terms_and_conditions && (
            <p className="text-gray-700 mt-4">
              <strong>Terms & Conditions:</strong>{" "}
              {estimate.notes.terms_and_conditions}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default EstimateDetails;